.toggle-switch-container {
    display: flex;
}

.toggle-switch-btn {
    margin-left: auto;
    background-color: #b7b9ba;
    border: 1px solid #aaa;
    border-radius: 99px;
    width: 60px;
    height: 30px;
    transition: background-color 0.1s ease, border-color 0.2s ease;
    cursor: pointer;
    position: relative;
}

.toggle-switch-thumb {
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 99px;
    transform: translateX(0);
    transform: left 0.15s ease;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
}

.toggle-switch-on {
    background-color: var(--stannah-support-green);
}

.toggle-switch-btn:hover {
    border-color: #6f6f6f;
}

.toggle-switch-on .toggle-switch-thumb {
    left: calc(50px - 15px);
}

.toggle-switch-checked-value {
    margin-left: 20px;
}