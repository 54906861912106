:root {
  --stannah-pink: #f8f2f0;
  --stannah-black: #333333;
  --stannah-white: #ffffff;
  --stannah-grey: #dddad9;
  --stannah-dark-grey: #333333;
  --stannah-mid-grey: #5c5555;
  --stannah-web-grey: #7a7575;
  --stannah-web-light-grey: #f6f3f2;
  --stannah-web-pale-grey: #dddad9;
  --stannah-teal: #07444d;
  --stannah-bordeux: #790000;
  --stannah-putty: #ece4e1;
  --stannah-invert-text: #ffffff;
  --stannah-support-orange: #f0ab00;
  --stannah-support-green: #99bc00;
  --stannah-background: #fcfcfc;
  --stannah-error: red;
  --stannah-toast-error: #e1173c;
  --stannah-ok: #048263;
}

html,
body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  position: relative;
  border-radius: 30px;
  border: 2px solid var(--stannah-teal);
  box-sizing: border-box;
  font-weight: 500;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 35px;
  text-align: center;
  font-size: 18px;
  background-color: var(--stannah-teal);
  color: var(--stannah-white);
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

.secondary-button {
  color: var(--stannah-teal);
  background-color: var(--stannah-white);
  border: 2px solid var(--stannah-teal);
}

& #next_button,
& #back_button {
  float: right;
}

.button:disabled {
  border: var(--stannah-web-light-grey);
  color: var(--stannah-web-grey);
  background-color: var(--stannah-web-light-grey);
  cursor: not-allowed;
}

.topPanel {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: var(--stannah-pink);
  padding: 10px;
  border-radius: 5px;
  max-width: 250px;
  z-index: 2;
}

aside {
  position: absolute;
  right: 0;
  background-color: white;
}

.modal-container {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2;
}

.modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-btn-div {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.email-chips {
  display: flex;
  align-items: left;
}

.modal-confirm-btn {
  margin-left: 20px;
}

.modal-close-btn,
.modal-confirm-dialog-btn {
  font-size: 14px;
  padding-left: 35px;
  padding-right: 35px;
}

.modal-close-btn {
  color: var(--stannah-teal);
  background-color: var(--stannah-invert-text);
}

.modal-confirm-dialog-btn {
  margin-left: 20px;
}

.dialog-close-icon {
  position: absolute !important;
  right: 30px;

  &:hover {
    cursor: pointer;
  }
}

.toast.error {
  background-color: var(--stannah-toast-error) !important;

  .icon {
    content: url('./assets/Alert-info.svg');
  }
}
