.picker {
  min-height: 50px;
  min-width: 50px;
  overflow: visible;
  font-size: 18px;
  margin-bottom: 15px;
  position: relative;

  & > h1 {
    font-size: 24px;
    color: var(--stannah-black);
  }

  & > h2 {
    font-size: 16px;
    color: var(--stannah-black);
  }
}

.picker .dropbutton {
  font-size: 17px;
  font-weight: bold;
  border: none;
  outline: none;
  padding: 14px 16px;
  background-color: inherit;
  margin: 0;
}


.picker-button {
  background-color: transparent;
  border: 2px solid var(--stannah-black);
  border-radius: 8px;
  text-align: left;
  color: var(--stannah-black);
  padding: 16px;
  width: 500px;
  font-size: 16px;

  cursor: pointer;
}

.required-warning {
  color: var(--stannah-error);
}

.picker-button :disabled {
  pointer-events: none;
}

.picker-content {
  display: none;
  text-align: left;
  width: 500px;
  border: 2px solid var(--stannah-black);
  background-color: #fff;
  border-radius: 8px;
  min-width: 160px;
  z-index: 1;
  position: absolute;
}

.picker-content button {
  color: var(--stannah-black);
  background-color: #fff;
  width: 100%;
  text-align: left;
  border-radius: 8px;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border: none;
}

.picker-content button:hover {
  background-color: var(--stannah-bordeux);
  color: #fff;
}

.picker:hover .picker-content {
  display: block;
}

.picker:hover .picker-button {
  color: var(--stannah-bordeux);
}
