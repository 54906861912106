.employee-form {
  height: 100%;
  display: flex;
  flex-grow: 1;

  & form {
    height: 100%;
  }
}
.employee-form-role-page,
.employee-form-employee-page {
  display: flex;
  height: 100%;
  flex-direction: column;

  & input[name="id"] {
    width: 200px;
  }
 
  & .delete-role-button {
    color: var(--stannah-teal);
    background-color: var(--stannah-white);
  }

  & #submit_button {
    float: right;
    color: var(--stannah-white);
    background-color: var(--stannah-teal);
    margin-left: 20px;
  }
}

.employee-form-inputs {
  flex-grow: 1;
}

.employee-form-creation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h3 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    margin-bottom: 0px;
    color: var(--stannah-dark-grey);
  }

  & h4 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    color: var(--stannah-web-grey);
  }

  & button {
    float: right;
  }
}

.employee-form-role-employees {
  display: flex;

  & .delete-role-button {
    border: none;

    &:hover {
      cursor: pointer;
    }

    & .delete-icon {
      width: 24px;
    }
  }
}

.employee-form-errors {
  flex-grow: 1;
  padding: 20px;
  align-content: end;
  font-size: 14px;
  color: var(--stannah-web-grey);
}
