.card {
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-bottom: 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  background-color: var(--stannah-white);
  border: 1px solid var(--stannah-web-pale-grey);
  box-sizing: border-box;
  width: 460px;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  color: var(--stannah-dark-grey);

  & > header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 24px;

    & .card-title-area {
      flex-grow: 1;

      & > h1 {
        color: var(--stannah-dark-grey);
        font-size: 18px;
        box-sizing: border-box;
        font-weight: 800;
        margin-top: 0px;
        margin-bottom: 3px;
      }

      & > h2 {
        color: var(--stannah-web-grey);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        margin: 0px;
      }
    }

    & .card-button {
      flex-grow: 0;
      flex-shrink: 0;
      color: var(--stannah-web-grey);
      background-color: transparent;
      border: 0px;

      & :hover {
        cursor: pointer;
      }
    }
  }

  & > footer {
    width: 100%;
    padding: 24px 12px;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 18px;
    line-height: 24px;
    color: var(--stannah-web-grey);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card-content {
  width: 100%;
  max-height: 900px;
  box-sizing: border-box;
  overflow-y: auto;

  & > progress {
    margin: 24px;
  }
}

.card-normal {
  flex-shrink: 0;
  flex-grow: 0;
}

.card-double {
  width: 932px;
  flex-grow: 0;
}

.card-wide {
  width: 100%;
}

@media (min-width: 960px) {
  .card-wide {
    width: 932px;
  }
}

@media (min-width: 1444px) {
  .card-wide {
    width: 1404px;
  }
}

.card-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.card-detail {
  display: flex;
  text-align: left;
  font-size: 18px;
  margin: 0px 24px;
  padding: 18px 0px;
  border-bottom: 1px solid var(--stannah-web-pale-grey);
  gap: 4px;
}

.card-col {
  flex: 1;        /* distributes space on the line equally among items */
}

.card-detail-icon {
  width: 46px;
  margin: 0px 24px;
  flex-grow: 0;
  flex-shrink: 0;
}

.card-detail-value {
  flex-grow: 1;
}

.titled-card-detail-value {
  font-weight: 500;
  flex-grow: 1;
}

.card-group {
  display: flex;
  flex-direction: column;

  & > h1 {
    color: var(--stannah-dark-grey);
    font-size: 18px;
    box-sizing: border-box;
    font-weight: 500;
    margin: 12px 24px 3px 24px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  & > h2 {
    color: var(--stannah-web-grey);
    font-size: 14px;
    line-height: 20px;
    margin: 0px 24px 24px 24px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.card-address-info {
  display: flex;
  flex-direction: column;
  padding: 32px 0px;

  & > h1 {
    color: var(--stannah-dark-grey);
    font-size: 18px;
    box-sizing: border-box;
    font-weight: 800;
    margin-top: 0px;
    margin-bottom: 24px;
  }
}

.card-address-info-address li:first-child {
  font-weight: 600;
}

.card-address-info-content {
  display: flex;
  margin: 0px 24px;

  & > svg {
    width: 50px;
  }

  & > ul {
    padding: 0;
    margin-top: 0px;

    & > li {
      list-style-type: none;
    }

  }
}

