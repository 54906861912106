.side-modal-button-bar {
  bottom: 0px;
  padding-top: 2rem;
  height: 4.5rem;
  flex-grow: 0;
  display: flex;
  gap: 2rem;

  & #cancel_admin_button {
    margin-right: auto;
  }

  & #submit_button {
    color: var(--stannah-white);
    background-color: var(--stannah-teal);
  }

  & #submit_button:disabled {
    color: var(--stannah-web-grey);
    background-color: var(--stannah-web-light-grey);
    border: 2px solid var(--stannah-web-light-grey);
  }

}
