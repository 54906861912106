 .delete-carer-button {
     color: var(--stannah-teal);
     background-color: var(--stannah-white);
     border: none;
     margin-top: 20px;
     margin-left: 20px;
 }

 #create_carer_button {
     margin-left: 20px;
 }

 .carer-form-upper-row {
     display: flex;
     align-items: left;
     justify-content: space-between;
     width: 100%;

     & h3 {
         font-size: 18px;
         line-height: 24px;
         font-weight: 800;
         margin-bottom: 0px;
         color: var(--stannah-dark-grey);
     }

     & h4 {
         font-size: 14px;
         line-height: 20px;
         margin-top: 0px;
         color: var(--stannah-web-grey);
     }

 }

 .carer-form-row {
     display: flex;
     align-items: left;
     width: 100%;
     & .formfield {
        width: auto !important;
     }
 }