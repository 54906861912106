.one-below-another {
    display: flex;
    flex-direction: column;
    height: 90%;
}

.bottom-buttons-bar {
    width: 95%;
}

